<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.661" x2="0.283" y2="1.293">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_shoes" transform="translate(-1018 -2975)">
    <g data-name="Group 427" id="Group_427" transform="translate(189 -29)">
     <rect :fill="background" data-name="Rectangle 217" height="183" id="Rectangle_217" transform="translate(829 3004)" width="183"/>
     <g id="sport-shoe" transform="translate(866.997 2966.662)">
      <path d="M113.054,282.993l-.576-.847-.477.906c-3.111,5.909-13.451,9.441-21.652,9.441-10.442,0-62-1.569-68.709-1.952-1.327-.079-2.713-.115-4.362-.115-1.023,0-1.973.013-2.891.025-.8.011-1.551.021-2.258.021a16.677,16.677,0,0,1-5.347-1.022,7.567,7.567,0,0,0-2.1-.5,1.4,1.4,0,0,0-1.043.4,2.241,2.241,0,0,0-.479,1.682c.1,6.948,3.782,7.485,8.968,7.485.712,0,1.47-.01,2.274-.021.914-.012,1.86-.025,2.875-.025,1.624,0,2.987.036,4.29.113,6.72.383,58.328,1.953,68.78,1.953a35.482,35.482,0,0,0,15.688-3.768c5.2-2.715,8.062-6.369,8.062-10.289A6.081,6.081,0,0,0,113.054,282.993Z" data-name="Path 505" fill="url(#linear-gradient)" id="Path_505" transform="translate(-2.383 -139.358)"/>
      <path d="M9.051,147.777c.707,0,1.459-.01,2.257-.021.907-.012,1.846-.025,2.853-.025,1.615,0,2.966.036,4.257.113,6.667.383,57.874,1.953,68.247,1.953a34.987,34.987,0,0,0,15.571-3.769c5.159-2.716,8-6.369,8-10.288,0-5.683-6.484-7.519-13.349-9.462A55,55,0,0,1,86,122.371c-1.473-.791-3.719-1.938-6.456-3.309,0-.079.012-.158.012-.238a4.238,4.238,0,0,0-7-3.223l-3.042-1.493a4.232,4.232,0,0,0-6.7-3.27l-2.782-1.351a4.239,4.239,0,0,0-7.218-3.493c-9.045-4.368-16.461-7.915-17.125-8.233a2.322,2.322,0,0,0-1.309-.422,2.363,2.363,0,0,0-1.879.951l-5,6.7a3.528,3.528,0,0,0-.782,2.717c.3,1.776,2.131,3.034,3.6,4.045a7.148,7.148,0,0,1,1.6,1.291l-.006.006c-.814.8-3.428,1.981-7.291,1.981a20.143,20.143,0,0,1-6.806-1.235c-2.715-.989-3.837-3.7-4.739-5.881-.754-1.823-1.405-3.4-2.852-3.4-1.1,0-2.368.985-4.253,3.3C-.429,115.963-.129,128.408.089,137.5c.023.973.046,1.908.059,2.8C.25,147.241,3.9,147.777,9.051,147.777Zm53.71-25.25a2.014,2.014,0,0,1,1.838-.509c.884.265,2.975.828,4.254,1.146,1.094.272.106,1.132.106,1.132l-16.605,15.2a2.733,2.733,0,0,1-1.713.626c-1.654,0-4.862-.013-6.168-.013-1.086,0-.185-.917-.185-.917S62.758,122.528,62.761,122.528Zm-12.643-3.587a3.058,3.058,0,0,1,2.819-.777c.78.247,2.609.7,3.776,1.027,1,.279-.06,1.309-.061,1.31L36.1,139.136a2.3,2.3,0,0,1-2.092.87c-1.808,0-5.258.083-6.669.083-.8,0-.089-.858-.089-.858Z" data-name="Path 506" fill="url(#linear-gradient)" id="Path_506" transform="translate(0 0)"/>
     </g>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>